import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const links = [
  ['home', '/'],
  ['login'],
  ['modeler'],
  ['designer'],
  ['dresser'],
  ['assistant'],
]

const routes = links.map(link => ({
  path: link[1] ?? ('/' + link[0]),
  name: link[0],
  component: function () {
    return import('../views/' + link[0] + '.vue')
  }
}))

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

import Vue from 'vue'
import * as dressing from '../services/dressing'

Vue.use({
    install() {
        Vue.mixin({
            beforeCreate() {
                this.$dressing = dressing
            },
        })
    }
})
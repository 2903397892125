function create_default_dressing(user) {
    return {
        name: user.fname,
        user: user,
        allure: null,
        models: {},
        motifs: {},
        cloths: {},
        extras: {},
        tenues: [],
        constr: {},
    }
}

export function check_dressing_data(dressing, user) {
    const default_dressing = create_default_dressing(user)
    dressing.user = user
    if (!dressing.user) dressing.user = user
    if (!Array.isArray(dressing.tenues)) dressing.tenues = Object.values(dressing.tenues)
    if (!dressing.assistant) dressing.assistant = {
        user_infos: '',
        user_request: '',
        history: [],
    }
    if (!dressing.constr) dressing.constr = {
        'chemise': ['tshirt', 'hoodi', 'tenis', 'chinon', 'gx'],
        'tshirt': ['cravatte', 'pull sm'],
        'hoodi': ['chemise', 'cravatte', 'ville', 'grand manteau'],
        'zara': ['fedora'],
    }
    for (const prop in default_dressing) {
        if (!(prop in dressing)) dressing[prop] = default_dressing[prop]
    }
    for (const model of Object.values(dressing.models)) {
        if (!model.classe) {
            model.classe = 'all'
        }
    }
    return dressing
}

function get_dressing_key_name(user) {
    return user.conn + '-dressing'
}

export async function get_dressing(comp) {
    const connected = await comp.$api.auth.connected()
    if (!connected) location = '/login#next=' + location.pathname
    const user = await comp.$api.auth.user.get()
    const dressing_key_name = get_dressing_key_name(user)
    if (!await comp.$api.key.exists(dressing_key_name)) {
        console.log('created new dressing')
        await comp.$api.key.set(dressing_key_name, create_default_dressing(user))
    }
    const dressing = check_dressing_data(await comp.$api.key.get(dressing_key_name), user)
    return dressing
}

let save_to = null
const save_timer = 1000
export function save_dressing(comp, dressing, direct = false) {
    clearTimeout(save_to)
    save_to = setTimeout(() => {
        const dressing_key_name = get_dressing_key_name(dressing.user)
        comp.$api.key.set(dressing_key_name, dressing)
    }, direct ? 0 : save_timer)
}

export function svg_to_sheet(svg) {
    return {
        svg,
        index: 0,
        color_name: 'main'
    }
}

export function svgs_to_sheets(svgs) {
    return svgs.map(svg_to_sheet)
}

export function svgs_to_model(svgs) {
    const sheets = svgs_to_sheets(svgs)
    const model = {
        sheets
    }
    return model
}

function svg_to_paths_str(svg, color, transform, scale) {
    return (svg.paths
        .map(({ d, fill, tag, inner }) => {
            if (inner)
                return `<${tag} ${inner} transform="translate(${transform?.x ?? 0} ${transform?.y ?? 0}) scale(${scale ?? 1})" fill="${color ?? fill}" />`
            else return `<path d=${d} transform="translate(${transform?.x ?? 0} ${transform?.y ?? 0}) scale(${scale ?? 1})" fill="${color ?? fill}" />`
        }
        ))
        .join('')
}

function svg_object_to_svg({ svg: svg_obj }, motifs) {
    let extra = ''
    const paths = svg_to_paths_str(svg_obj)

    // ---- motifs
    if (motifs && motifs.length) {
        const cp_id = Math.random()
        const cp = `<clipPath id="${cp_id}">${paths}</clipPath>`

        const motifs_svg = motifs.filter(e => e.motif).map((motif, i) => {
            const paths = svg_to_paths_str(motif.svg.svg, motif.color)
            const view_box = /viewBox="(.*?)"/g.exec(motif.svg.svg.svg_tag)[1]
            const [, , motif_width, motif_height] = view_box.split(' ').map(n => parseFloat(n))
            const pattern_width = 1 / motif.repeat.x
            const pattern_height = 1 / motif.repeat.y
            const pattern_id = `pattern_${i}${cp_id}`
            const pattern = `<pattern id="${pattern_id}" x="0" y="0" width="${pattern_width}" height="${pattern_height}">${paths}</pattern>`
            const rect_width = motif_width * (motif.repeat.x * motif.repeat.offset)
            const rect_height = motif_height * (motif.repeat.y * motif.repeat.offset)
            const transform = `translate(${motif.offset.x} ${motif.offset.y}) scale(${motif.scale}) rotate(${-(motif.rotation ?? 0)})`
            const rect = `<rect fill="url(#${pattern_id})" width="${rect_width}" height="${rect_height}" transform="${transform}" />`
            return `<g clip-path="url(#${cp_id}${motif.clip ? '' : 'g'})">${pattern}${rect}</g>`
        })
        extra += cp + motifs_svg.join('')
    }

    // ---- final assembly
    return svg_obj.svg_tag
        + paths
        + extra
        + '</svg>'
}
export function sheet_data_to_svg(sheet) {
    const used_sheet = JSON.parse(JSON.stringify(sheet))
    used_sheet.svg.svg.paths.forEach(p => p.fill = sheet.color ?? p.fill)
    return svg_object_to_svg(used_sheet.svg, used_sheet.motifs)

}

export function cloth_to_sheet(dressing, cloth, variant_name) {
    const model = dressing.models[cloth.model]
    const variant = model.variants ? model.variants[variant_name ?? 'main'] : model
    return variant.sheets.map(sheet => ({
        ...sheet,
        color: cloth.colors[sheet.color_name],
        motifs: cloth.motifs.map(motif => ({
            ...motif,
            svg: dressing.motifs[motif.motif]
        }))
    }))
}

export function allure_to_sheets(allure) {
    return allure.sheets.map(sh => ({ ...sh, color: allure.colors[sh.color_name] }))
}

function augment_constraints(constraints) {
    const augmented = {}
    for (const name in constraints) {
        augmented[name] = [...constraints[name]]
        for (const forb of constraints[name]) {
            if (!augmented[forb]) augmented[forb] = [...(constraints[forb] ?? [])]
            augmented[forb].push(name)
        }
    }
    return augmented
}
export function generate_tenue(structure, given_constraints, existing_cloths = {}) {

    const constraints = augment_constraints(given_constraints)
    const available_clothes = Object.entries(structure).map(([, cloths]) => cloths).flat()

    function reduce_domain(cloth) {
        for (const constraint in constraints) {
            if (cloth.includes(constraint)) {
                const forbiddens = constraints[constraint]
                const cloths_to_forbid = available_clothes.filter((c) => {
                    return forbiddens
                        .filter((f) => c.includes(f))
                        .reduce((a, b) => a || b, false)
                })
                for (const cloth_to_forbid of cloths_to_forbid) {
                    available_clothes.splice(available_clothes.indexOf(cloth_to_forbid), 1)
                }
            }
        }
    }

    for (const classe in existing_cloths) {
        const cloth = existing_cloths[classe]
        reduce_domain(cloth)
    }

    const outfit = { ...existing_cloths }

    for (const classe in structure) {
        if (outfit[classe]) continue
        const cloths = structure[classe]
        const available = [...available_clothes].filter((c) =>
            cloths.includes(c)
        )
        if (!available.length) continue
        const cloth = available[Math.floor(Math.random() * available.length)]
        outfit[classe] = cloth
        reduce_domain(cloth)
    }
    return outfit
}
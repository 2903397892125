import Vue from 'vue'
import * as assistant from '../services/assistant.js'

Vue.use({
    install() {
        Vue.mixin({
            beforeCreate() {
                this.$assistant = assistant
            },
        })
    }
})
export function get_tomorrow_date() {
    const week_days = ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi']
    const months = ['janvier', 'fevrier', 'mars', 'avril', 'mai', 'juin', 'juillet', 'aout', 'septembre', 'octobre', 'novembre', 'decembre']
    const date = new Date()
    date.setDate(date.getDate() + 1)
    const day_string = week_days[date.getDay()] + ' ' + date.getDate() + ' ' + months[date.getMonth()] + ' ' + date.getFullYear()
    return day_string
}
export async function get_meteo(insee) {
    const token = 'nope'
    const URL = `https://api.meteo-concept.com/api/forecast/daily?token=${token}&insee=${insee}`
    const resp = await fetch(URL)
    const json = await resp.json()

    const day_string = get_tomorrow_date()

    const tomorrow = json.forecast[1]
    const { probafog, probafrost, probarain, rr10, tmax, tmin, sun_hours, weather, wind10m } = tomorrow
    const array = [
        ['probabilité de brouillard', probafog, '%'],
        ['probabilité de gel', probafrost, '%'],
        ['probabilité de pluie', probarain, '%'],
        ['pluie', rr10, 'mm'],
        ['température max', tmax, '°C'],
        ['température min', tmin, '°C'],
        ['ensoleillement', sun_hours, 'h'],
        ['vent', wind10m, 'Km/h'],
    ]
    const meteo_text = `${day_string}, ${code_temps[weather]}, ${array.map(ar => ar.join(' ')).join(', ')}`
    return meteo_text
}

export async function get_proposal(dressing, day_data, client_data, client_request, past_tries) {
    const { text, cloth_map } = generate_request(dressing, day_data, client_data, client_request, past_tries)
    const response = await generate_response(text)

    console.log(text)
    console.log(cloth_map)

    console.log(response)

    const get_habits = /01: (.*?)(?:\.|\n)/g.exec(response)
    const get_explan = /02: (.*?)\n/g.exec(response)

    console.log('get_habits', get_habits)
    console.log('get_explan', get_explan)

    const [, habits_str] = get_habits
    const [, explanations] = get_explan

    console.log('habits_str', habits_str)
    console.log('explanations', explanations)

    const habits_IDs = habits_str.split(',').map(n => n.replace(/\s/g, ''))
    console.log(habits_IDs)
    console.log(cloth_map)
    const habits = habits_IDs.map(ID => cloth_map[ID])
    console.log(habits)
    console.log(habits.map(name => [name, dressing.cloths[name]]))

    return { explanations, habits }

}

export function generate_request(dressing, day_data, client_data, client_request, past_tries) {
    const cloth_structure = {}

    const cloth_map = {}
    const type_map = {}

    for (const cloth_name in dressing.cloths) {
        const model_name = dressing.cloths[cloth_name].model
        const model = dressing.models[model_name]
        const classe = model.classe
        if (!(model_name in cloth_structure)) cloth_structure[model_name] = {}
        if (!(classe in type_map)) {
            type_map[classe] = parseInt(Math.random() * 800) + 100
        }
        const type_code = type_map[classe]
        const cloth_id = type_code + '_' + parseInt(Math.random() * 10000)
        cloth_map[cloth_id] = cloth_name
        const cloth = dressing.cloths[cloth_name]
        const color = Object.values(cloth.colors).join(', ')
        const motifs = cloth.motifs.length ? cloth.motifs.map(motif => motif.motif + ' ' + motif.color).join(', ') : 'aucun'
        cloth_structure[model_name][cloth_name] = `ID:${cloth_id}, classe:${classe}, couleur ${color}, motifs ${motifs}`
    }

    const dressing_data = Object.entries(cloth_structure).map(([type, cloths]) => {
        const cloths_str = Object.entries(cloths).map(([name, str]) => '    * "' + name + '":' + str).join('\n')
        return ` - Type:${type}:\n${cloths_str}\n`
    }).join('\n')

    const past_tries_str = past_tries.length == 0 ? '' :
        `Les essaies non concluant pour aujourd'hui sont les suivants:
    ${past_tries.map(({ explain, cloths }) => {
            return ' - habits:' + cloths.join(', ') + '. Explication client:"' + explain + '"'
        }).join('\n')}`

    const text = `
    Vous êtes un assitant d'habillage pour le matin, vous vous y connaissez beaucoup en fashion et sur comment s'habiller au jour le jour en fonction de la météo et du jour de la semaine.
    Vous avez accès à ce dressing rangé par classe d'habit et chaque habit avec ses informations spécifiques:
    ${dressing_data}
    
    Voici les informations du jour: ${day_data}.
    Voici la description du client: "${client_data}".
    La demande du client est la suivante: "${client_request}".

    ${past_tries_str}
    
    Votre réponse est sous ce format:
    "
    - 01: <liste des ID d'habit à mettre séparés par une virgule>
    - 02: <explication textuelle qui indique les choix faits pour les habits>
    "

    Ne donnez votre réponse que sous ce format là, pas de saut de ligne intutile.

    Pour chaque classe d'habit, choisir un seul habit à porter et reporter son ID dans la section "01".
    Il n'est pas obligatoire de choisir d'habit pour une classe.
    Les ID habit fonctionnent ainsi: <code_classe>_<code_habit>.
    Le code classe fait référence à la classe de l'habit.
    Le code habit désigne l'habit dans la classe.
    Il est interdit de placer dans la liste d'ID plusieurs habits avec le même code classe.
    
    Dans votre explication, vous expliquez pour chaque habit que vous proposez pourquoi vous l'avez choisi, vous vous adresserez directement au client en le vouvoyant et n'indiquez pas les ID des habits quand vous les citez. L'explication ne doit pas comporter de retour à la ligne sauf à la fin.
    `
    return { text, cloth_map }
}

export async function generate_response(request) {

    const { Configuration, OpenAIApi } = require("openai")

    const apiKey = "nope"
    const configuration = new Configuration({
        apiKey: apiKey,
    })
    const openai = new OpenAIApi(configuration)

    const response = await openai.createCompletion({
        model: "text-davinci-003",
        prompt: request,
        temperature: 0.5,
        max_tokens: 1024,
        top_p: 1,
        frequency_penalty: 0,
        presence_penalty: 0,
    })
    const text = response.data.choices[0].text + '\n'
    return text
}


const code_temps = Object.fromEntries(`0	Soleil
1	Peu nuageux
2	Ciel voilé
3	Nuageux
4	Très nuageux
5	Couvert
6	Brouillard
7	Brouillard givrant
10	Pluie faible
11	Pluie modérée
12	Pluie forte
13	Pluie faible verglaçante
14	Pluie modérée verglaçante
15	Pluie forte verglaçante
16	Bruine
20	Neige faible
21	Neige modérée
22	Neige forte
30	Pluie et neige mêlées faibles
31	Pluie et neige mêlées modérées
32	Pluie et neige mêlées fortes
40	Averses de pluie locales et faibles
41	Averses de pluie locales
42	Averses locales et fortes
43	Averses de pluie faibles
44	Averses de pluie
45	Averses de pluie fortes
46	Averses de pluie faibles et fréquentes
47	Averses de pluie fréquentes
48	Averses de pluie fortes et fréquentes
60	Averses de neige localisées et faibles
61	Averses de neige localisées
62	Averses de neige localisées et fortes
63	Averses de neige faibles
64	Averses de neige
65	Averses de neige fortes
66	Averses de neige faibles et fréquentes
67	Averses de neige fréquentes
68	Averses de neige fortes et fréquentes
70	Averses de pluie et neige mêlées localisées et faibles
71	Averses de pluie et neige mêlées localisées
72	Averses de pluie et neige mêlées localisées et fortes
73	Averses de pluie et neige mêlées faibles
74	Averses de pluie et neige mêlées
75	Averses de pluie et neige mêlées fortes
76	Averses de pluie et neige mêlées faibles et nombreuses
77	Averses de pluie et neige mêlées fréquentes
78	Averses de pluie et neige mêlées fortes et fréquentes
100	Orages faibles et locaux
101	Orages locaux
102	Orages fort et locaux
103	Orages faibles
104	Orages
105	Orages forts
106	Orages faibles et fréquents
107	Orages fréquents
108	Orages forts et fréquents
120	Orages faibles et locaux de neige ou grésil
121	Orages locaux de neige ou grésil
122	Orages locaux de neige ou grésil
123	Orages faibles de neige ou grésil
124	Orages de neige ou grésil
125	Orages de neige ou grésil
126	Orages faibles et fréquents de neige ou grésil
127	Orages fréquents de neige ou grésil
128	Orages fréquents de neige ou grésil
130	Orages faibles et locaux de pluie et neige mêlées ou grésil
131	Orages locaux de pluie et neige mêlées ou grésil
132	Orages fort et locaux de pluie et neige mêlées ou grésil
133	Orages faibles de pluie et neige mêlées ou grésil
134	Orages de pluie et neige mêlées ou grésil
135	Orages forts de pluie et neige mêlées ou grésil
136	Orages faibles et fréquents de pluie et neige mêlées ou grésil
137	Orages fréquents de pluie et neige mêlées ou grésil
138	Orages forts et fréquents de pluie et neige mêlées ou grésil
140	Pluies orageuses
141	Pluie et neige mêlées à caractère orageux
142	Neige à caractère orageux
210	Pluie faible intermittente
211	Pluie modérée intermittente
212	Pluie forte intermittente
220	Neige faible intermittente
221	Neige modérée intermittente
222	Neige forte intermittente
230	Pluie et neige mêlées
231	Pluie et neige mêlées
232	Pluie et neige mêlées
235	Averses de grêle`.split('\n').map(elm => elm.split('\t')))
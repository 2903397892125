<template>
    <v-app>
        <router-view />
    </v-app>
</template>

<script>
export default {
    name: "App",

    data: () => ({
        //
    }),
}
</script>

<style lang="scss">
.v-application {
    background: #e5e5e5 !important ;
    color: #555 !important;
}
.v-card__title {
    color: #555;
}
.v-btn {
    color: #777 !important;
    background-color: #fff !important ;
    box-shadow: none !important;
    border-radius: 100px !important;

    &.v-btn--text,
    &.v-btn--icon {
        background: transparent !important;
    }
}

.v-expansion-panel {
    &::before {
        box-shadow: none !important;
    }
    .v-expansion-panel-content {
        .v-btn {
            color: #777 !important;
            background-color: #eee !important ;
            &.v-btn--text,
            &.v-btn--icon {
                color: #bbb !important;
                background: transparent !important;
            }
        }
    }
}
</style>

import Vue from 'vue'
import * as utils from '../services/utils'

Vue.use({
    install() {
        Vue.mixin({
            beforeCreate() {
                this.$utils = utils
            },
        })
    }
})
export function get_file_content(file) {
    const reader = new FileReader()
    reader.readAsText(file, "UTF-8")
    return new Promise((ok, err) => {
        reader.onload = function (evt) {
            ok(evt.target.result)
        }
        reader.onerror = function (evt) {
            err("file error", evt)
        }
    })
}

export async function get_svg_content(file) {
    return {
        name: file.name,
        svg: svg_parser(await get_file_content(file)),
    }
}

export function svg_parser(svg) {
    const only_svg = svg.replace(/<\?xml(.*?)\?>/g, '').replace(/<\/svg>/g, '')
    const svg_tag = /<svg(.*?)>/g.exec(only_svg)[0]
    const inside = only_svg.replace(svg_tag, '')
    function find_paths(text) {
        const paths = text.match(/<([a-z]+) (.*?)\/>/g).map(match => {
            const tag = /<([a-z]+) .*?\/>/g.exec(match)[1]
            let fill = /fill="(.*?)"/g.exec(match)?.[1]
            if (fill?.charAt(0) != '#') fill = '#555555'
            const inner = match.replace('<' + tag + ' ', '').replace('fill="' + fill + '"', '').replace('/>', '')
            return { tag, fill, inner }
        })
        return paths
    }
    const paths = find_paths(inside)
    return { svg_tag, paths }
}

export function load_files(multiple = false, accept = null, is_svg = true) {
    return new Promise((ok) => {
        const file_input = document.createElement("input")
        file_input.setAttribute("type", "file")
        if (multiple) file_input.setAttribute("multiple", "")
        if (accept) file_input.setAttribute("accept", accept.join(','))
        document.body.appendChild(file_input)
        let file_set = false
        file_input.onchange = async () => {
            file_set = true
            const files = Array.from(file_input.files)
            const svgs = await Promise.all(
                files.map((file) => is_svg ? get_svg_content(file) : get_file_content(file))
            )
            multiple ? ok(svgs) : ok(svgs[0])
        }

        function checkIt() {
            setTimeout(() => { if (!file_set && !file_input.value.length) ok(null) }, 1000)
            document.body.onfocus = null
        }
        file_input.onclick = () => document.body.onfocus = checkIt
        file_input.click()
        file_input.remove()
    })
}

export function download_file(name, content) {
    const element = document.createElement('a')
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(content))
    element.setAttribute('download', name)
    element.style.display = 'none'
    document.body.appendChild(element)
    element.click()
    document.body.removeChild(element)
}